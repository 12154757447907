<template>
<div id="usuario-store">
    <v-dialog scrollable v-model="dialog" persistent max-width="600" transition="dialog-top-transition">
        <v-card :loading="loading" :disabled="loading">
            <v-toolbar class="elevation-custom-table">
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Nuevo usuario <span v-if="dirty">*</span></span>
                <v-spacer></v-spacer> 
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="mb-2">
                        <v-text-field @input="$v.usuario.name.$touch()" @blur="$v.usuario.name.$touch()" :error-messages="nameErrors" placeholder="Nombre" v-model="usuario.name" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Nombre<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mb-2">
                        <v-text-field @input="$v.usuario.email.$touch()" @blur="$v.usuario.email.$touch()" :error-messages="emailErrors" placeholder="Correo electrónico" v-model="usuario.email" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Correo electrónico<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mb-2">
                        <v-select @input="$v.usuario.role.$touch()" @blur="$v.usuario.role.$touch()" :error-messages="roleErrors" placeholder="Seleccione una opción" v-model="usuario.role" :items="roles" item-text="text" item-value="value" label="Rol" persistent-placeholder hide-details="auto">
                            <template #label>
                                Rol<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field @input="$v.usuario.password.$touch()" @blur="$v.usuario.password.$touch()" :error-messages="passwordErrors" placeholder="Minimo 8 caracteres" type="password" v-model="usuario.password" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Contraseña<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" class="mb-2">
                        <v-text-field @input="$v.usuario.passwordConfirm.$touch()" @blur="$v.usuario.passwordConfirm.$touch()" :error-messages="passwordConfirmErrors" placeholder="Confirmar la contraseña" type="password" v-model="usuario.passwordConfirm" color="primary" persistent-placeholder hide-details="auto">
                            <template #label>
                                Confirmar contraseña<span class="red--text"><strong> *</strong></span>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="text-none" color="grey darken-2" text @click="dirty == false ? $router.push('/usuarios') : unsaved = true">
                    Cancelar
                </v-btn>
                <v-btn color="green darken-1" class="text-none" text @click="store" :disabled="$v.usuario.$invalid">
                    Agregar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="unsaved" persistent max-width="300" transition="dialog-top-transition">
        <v-card color>
            <v-toolbar flat>
                <v-spacer></v-spacer>
                <span class="font-weight-bold">Alerta</span>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="text-center">
                Tus cambios no se guardaran, ¿deseas continuar?
            </v-card-text>
            <v-card-actions>
                <v-btn text class="text-none" color="grey darken-1" @click="unsaved = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn text class="text-none" color="red" @click="$router.push('/usuarios')">Salir sin guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
export default {
    name: 'UsuarioStore',
    data: () => ({
        loading: false,
        unsaved: false,
        dialog: true,
        roles: [
            { text: 'Colaborador', value: 'USER' },
            { text: 'Administrador', value: 'ADMIN' },
            { text: 'Super administrador', value: 'SUPERADMIN' }
        ],
        imagen: {
            file: null,
            thumbnail: null
        },
        usuarioUntouched: JSON.stringify({
            name: null,
            email: null,
            role: null,
            password: null,
            passwordConfirm: null
        }),
        usuario: {
            name: null,
            email: null,
            role: null,
            password: null,
            passwordConfirm: null
        }
    }),
    methods: {
        async store() {
            this.loading = true;
            await this.$axios.post('/api/user', this.usuario).then(response => {
                if(response.status == 201){
                    this.$store.commit('usuario/INSERT_USUARIO', response.data);
                    this.$store.commit('snackbar/SHOW_SNACKBAR', { message: 'Usuario agregado.' });
                    this.$router.push('/usuarios');
                }
            }).catch(error => {
                this.$store.commit('error/SHOW_ERROR', { message: error });
            }).then(() => {
                this.loading = false;
            });
        }
    },
    computed: {
        dirty() {
            return this.usuarioUntouched != JSON.stringify(this.usuario) || this.imagen.file != null;
        },
        nameErrors() {
            const errors = [];
            if (this.$v.usuario.name.$dirty){
                if(!this.$v.usuario.name.required){
                    errors.push('Este campo es obligatorio.')
                }
            }
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (this.$v.usuario.email.$dirty){
                if (!this.$v.usuario.email.required) {
                    errors.push('Este campo es obligatorio.')
                }
                if (!this.$v.usuario.email.email) {
                    errors.push('Este campo debe ser una direccción de correo electrónico valida.')
                }
            }
            return errors;
        },
        roleErrors() {
            const errors = [];
            if(this.$v.usuario.role.$dirty){
                if (!this.$v.usuario.role.required) {
                    errors.push('Este campo es obligatorio.')
                }
            }
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if(this.$v.usuario.password.$dirty){
                if (!this.$v.usuario.password.required) {
                    errors.push('Este campo es obligatorio.')
                }
                if (!this.$v.usuario.password.minLength) {
                    errors.push('La contraseña debe tener al menos 8 caracteres.')
                }
            }
            return errors;
        },
        passwordConfirmErrors() {
            const errors = [];
            if(this.$v.usuario.passwordConfirm.$dirty){
                if(!this.$v.usuario.passwordConfirm.sameAsPassword){
                    errors.push('Los valores no coinciden.')
                }
            }
            return errors;
        }
    },
    props: {
        height: Number
    },
    validations: {
        usuario: {
            name: {
                required
            },
            role: {
                required
            },
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(8)
            },
            passwordConfirm: {
                sameAsPassword: sameAs('password')
            }
        }
    }
}
</script>
